import { Component, EventEmitter, Inject, Input, Output } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { RadioOption } from "@/components/shared/radio-list/radio-list.component";
import { USER_IS_AT_LEAST_ADMIN } from "@/_services";
import { Observable } from "rxjs";

@Component({
    selector: "app-template-list-filter-sidebar",
    templateUrl: "./template-list-filter-sidebar.component.html",
    styleUrl: "./template-list-filter-sidebar.component.scss",
})
export class TemplateListFilterSidebarComponent {
    @Input() filterForm: UntypedFormGroup;
    @Output() closeButtonClicked: EventEmitter<void> = new EventEmitter<void>();

    @Input() typeList: RadioOption[] = [];
    @Input() categoryList: RadioOption[] = [];
    @Input() ownerList: RadioOption[] = [];

    constructor(
        @Inject(USER_IS_AT_LEAST_ADMIN) public isAdmin$: Observable<boolean>,
    ) {}

    ngOnInit() {}

    setCheckboxValue(control: UntypedFormControl, state: boolean, value: any) {
        if (state) {
            return control.setValue([...control.value, value.toLowerCase()]);
        }
        return control.setValue([
            ...control.value.filter(
                (currentValue) =>
                    currentValue.toLowerCase() !== value.toLowerCase(),
            ),
        ]);
    }

    close() {
        this.closeButtonClicked.emit();
    }
}
