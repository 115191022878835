<app-page-topbar [title]="'Form Templates'">
    <a *appDesktop class="button" (click)="createNewAssessmentForm()">
        New Form
    </a>
</app-page-topbar>

<app-template-list></app-template-list>

<div class="right-corner-control blue" *appMobile>
    <a (click)="createNewAssessmentForm()">
        <span>New Form</span>
    </a>
</div>
