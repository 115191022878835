import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Route, RouterModule } from "@angular/router";
import { SharedComponentsModule } from "@/components/shared/shared-components.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CreateAssessmentComponent } from "./create-assessment/create-assessment.component";
import { NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { FormPreviewModalComponent } from "@/components/assessment/form-preview-modal/form-preview-modal.component";
import { DefaultAssessmentRedirectGuard } from "@/_guards/default-assessment-redirect.guard";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { AssessmentComponent } from "./assessment.component";
import { MatMenuModule } from "@angular/material/menu";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { AssessmentSettingsModalComponent } from "./assessment-settings-modal/assessment-settings-modal.component";
import { FlightListComponent } from "@/components/shared/flight-list";
import { FormListComponent } from "./form-list/form-list.component";
import { AvailableForMobileGuard } from "@/_guards/available-for-mobile.guard";
import { FormResolverService } from "@/_resolvers/form-resolver.service";
import { GeneralUserGuard } from "@/_guards/general-user.guard";
import { TemplateListComponent } from "@/components/assessment/create-assessment/template-list/template-list.component";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatExpansionModule } from "@angular/material/expansion";
import { TemplateCardComponent } from "@/components/assessment/create-assessment/template-card/template-card.component";
import { TemplateListFilterSidebarComponent } from "@/components/assessment/create-assessment/template-list-filter-sidebar/template-list-filter-sidebar.component";
import { TemplateListFilterTopbarComponent } from "@/components/assessment/create-assessment/template-list-filter-topbar/template-list-filter-topbar.component";

const ROUTES: Route[] = [
    {
        path: "",
        component: FormListComponent,
        canActivate: [AvailableForMobileGuard],
        data: { default: "/assessments/default" },
    },
    {
        path: "",
        pathMatch: "full",
        redirectTo: "default",
    },
    {
        path: "new",
        component: CreateAssessmentComponent,
        canActivate: [GeneralUserGuard],
    },
    {
        path: "default",
        pathMatch: "full",
        children: [],
        canActivate: [DefaultAssessmentRedirectGuard],
    },
    {
        path: ":assessmentId",
        component: AssessmentComponent,
        resolve: { form: FormResolverService },
        children: [
            {
                path: "",
                component: FlightListComponent,
            },
            {
                path: "edit",
                loadChildren: () =>
                    import("./assessment-editor/assessment-editor.module").then(
                        (m) => m.AssessmentEditorModule,
                    ),
            },
        ],
    },
];

@NgModule({
    declarations: [
        CreateAssessmentComponent,
        TemplateListComponent,
        TemplateListFilterSidebarComponent,
        TemplateListFilterTopbarComponent,
        TemplateCardComponent,
        FormPreviewModalComponent,
        AssessmentComponent,
        AssessmentSettingsModalComponent,
        FormListComponent,
    ],
    imports: [
        CommonModule,
        RouterModule.forChild(ROUTES),
        FormsModule,
        ReactiveFormsModule,
        SharedComponentsModule,
        NgbTooltipModule,
        MatSlideToggleModule,
        DragDropModule,
        MatMenuModule,
        MatSidenavModule,
        MatExpansionModule,
        InfiniteScrollModule,
        MatMenuModule,
    ],
})
export class AssessmentModule {}
