import { Component, EventEmitter, Inject, Input, Output } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { RadioOption } from "@/components/shared/radio-list/radio-list.component";
import { USER_IS_AT_LEAST_ADMIN } from "@/_services";
import { Observable } from "rxjs";

@Component({
    selector: "app-template-list-filter-topbar",
    templateUrl: "./template-list-filter-topbar.component.html",
    styleUrl: "./template-list-filter-topbar.component.scss",
})
export class TemplateListFilterTopbarComponent {
    @Input() filterForm: UntypedFormGroup;
    @Input() currentFilters;
    @Output() filterButtonClicked: EventEmitter<void> =
        new EventEmitter<void>();

    @Input() typeList: RadioOption[] = [];
    @Input() categoryList: RadioOption[] = [];
    @Input() ownerList: RadioOption[] = [];

    constructor(
        @Inject(USER_IS_AT_LEAST_ADMIN) public isAdmin$: Observable<boolean>,
    ) {}

    ngOnInit() {}

    openFilterMenu() {
        this.filterButtonClicked.emit();
    }

    isDisplayable(filter) {
        if (filter.key === "airport" && filter.value._id) {
            return true;
        }

        if (filter.key === "airport" && !filter.value._id) {
            return false;
        }

        return filter.value.length;
    }

    resetFilter(filterKey: string) {
        if (filterKey === "status" || filterKey === "submissionDate") {
            return this.filterForm.get(filterKey).setValue([]);
        }
        return this.filterForm.get(filterKey).setValue("");
    }
}
