import { Injectable } from "@angular/core";
import { AssessmentService } from "@/_services";
import { ActivatedRouteSnapshot } from "@angular/router";
import { catchError, Observable, of } from "rxjs";
import { shareReplay, startWith, switchMap } from "rxjs/operators";
import { AssessmentDto } from "@/_models";

@Injectable({
    providedIn: "root",
})
export class FormResolverService {
    constructor(private assessmentService: AssessmentService) {}

    resolve(
        route: ActivatedRouteSnapshot,
    ): Observable<Observable<AssessmentDto>> {
        const formId = route.params["assessmentId"];
        return of(
            this.assessmentService.assessmentChanges
                .pipe(startWith(false))
                .pipe(
                    switchMap(() =>
                        this.assessmentService.getAssessment(formId),
                    ),
                    catchError(() => of(null)),
                    shareReplay(),
                ),
        );
    }
}
