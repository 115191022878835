import { Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from "@angular/router";
import { map, Observable } from "rxjs";
import { AssessmentService } from "@/_services";
import { AssessmentDto } from "@/_models";

@Injectable({
    providedIn: "root",
})
export class DefaultAssessmentRedirectGuard {
    constructor(
        private assessmentService: AssessmentService,
        private router: Router,
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this.assessmentService.listAssessments().pipe(
            map(
                (assessments) =>
                    assessments.find((asmt) => asmt.isDefault) ||
                    assessments[0],
            ),
            map((redirectAssessment: AssessmentDto) => {
                if (!redirectAssessment) {
                    return this.router.createUrlTree(["/profile/users"]);
                }
                return this.router.createUrlTree([
                    "assessments",
                    redirectAssessment._id,
                ]);
            }),
        );
    }
}
