import { Component } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import {
    BehaviorSubject,
    combineLatest,
    Observable,
    Subscription,
    tap,
} from "rxjs";
import { TableColumn } from "@/components/shared/table-header/table-header.component";
import { AssessmentDto, Flight, FormCategory, FormType } from "@/_models";
import {
    debounceTime,
    distinctUntilChanged,
    map,
    shareReplay,
    startWith,
    switchMap,
} from "rxjs/operators";
import { AssessmentService } from "@/_services";
import { RadioOption } from "@/components/shared/radio-list/radio-list.component";
import { AccountService } from "@/_services/account.service";
import { stringEnumSelector } from "@/_utility/enum.util";

@Component({
    selector: "app-template-list",
    templateUrl: "./template-list.component.html",
    styleUrl: "./template-list.component.scss",
})
export class TemplateListComponent {
    currentFilters = null;
    currentSorting: { key: string; value: string } = {
        key: "type",
        value: "desc",
    };

    filterForm: FormGroup;
    filterFormSubscription: Subscription;

    /* for filters purposes */
    formattedTypeList: RadioOption[];
    formattedCategoryList: RadioOption[];
    ownerList$: Observable<RadioOption[]>;

    columns: TableColumn[] = [
        { label: "Title", value: "title", isDisplayed: true },
        { label: "Type", value: "type", isDisplayed: true },
        { label: "Category", value: "category", isDisplayed: true },
        { label: "Owner", value: "owner", isDisplayed: true },
    ];

    private currentSort$ = new BehaviorSubject({
        key: "type",
        value: "desc",
    });
    private currentFilters$: Observable<any>;
    formList$: Observable<AssessmentDto[]>;
    formTypeList$: Observable<FormType[]>;

    constructor(
        private accountService: AccountService,
        private formBuilder: FormBuilder,
        private assessmentService: AssessmentService,
    ) {}

    ngOnInit() {
        this.filterForm = this.formBuilder.group({
            title: [""],
            type: [""],
            category: [""],
            owner: [""],
        });

        this.formTypeList$ = this.assessmentService.formTypeList().pipe(
            tap((formTypeList) => {
                this.formattedTypeList = [
                    ...formTypeList.map((type) => ({
                        label: type.name,
                        value: type.type,
                    })),
                ];
                this.formattedCategoryList = stringEnumSelector(FormCategory);
            }),
            shareReplay(),
        );

        this.ownerList$ = this.accountService.getAccount().pipe(
            map((account) => {
                return [
                    { label: "All", value: "" },
                    { label: account.name, value: account._id },
                    { label: "Preflight Mitigator", value: "PFM" },
                ];
            }),
            shareReplay(),
        );

        this.currentFilters$ = this.buildFilterForm().pipe(shareReplay());

        this.formList$ = combineLatest([
            this.currentFilters$,
            this.currentSort$,
        ]).pipe(
            distinctUntilChanged(),
            switchMap(() => {
                const filterParams = this.filterForm.value;
                const sortParams = this.buildSortQuery();
                return this.assessmentService.getFormList(
                    filterParams,
                    sortParams,
                );
            }),
            map((list) => list.data),
            shareReplay(),
        );
    }

    ngOnDestroy(): void {
        if (this.filterFormSubscription) {
            this.filterFormSubscription.unsubscribe();
        }
    }

    buildFilterForm() {
        const sortKey = `form-template-sort`;
        if (!!localStorage.getItem(sortKey)) {
            this.setSorting(JSON.parse(localStorage.getItem(sortKey)));
        } else {
            localStorage.setItem(sortKey, JSON.stringify(this.currentSorting));
        }

        const localStorageKey = `form-template-filters`;
        this.currentFilters = null;

        if (!!localStorage.getItem(localStorageKey)) {
            const filters = JSON.parse(localStorage.getItem(localStorageKey));
            this.filterForm.patchValue(filters, {
                emitEvent: false,
                onlySelf: true,
            });
            this.currentFilters = { ...filters };
        }

        return this.filterForm.valueChanges.pipe(
            debounceTime(500),
            tap((changes) =>
                localStorage.setItem(localStorageKey, JSON.stringify(changes)),
            ),
            tap((changes) => (this.currentFilters = { ...changes })),
            startWith(this.filterForm.value),
        );
    }

    setSorting(sort: { key: string; value: string }) {
        this.currentSorting = sort;
        this.currentSort$.next(sort);
        localStorage.setItem(
            `form-template-sort`,
            JSON.stringify(this.currentSorting),
        );
    }

    buildSortQuery(): { [sortKey: string]: "asc" | "desc" } {
        if (this.currentSorting.key) {
            return {
                [this.currentSorting.key]: this.currentSorting.value as
                    | "asc"
                    | "desc",
            };
        }
        return {};
    }

    trackFlightId(index: number, flight: Flight) {
        return flight._id;
    }
}
