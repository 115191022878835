<div class="app-content filter">
    <div class="filter-header">
        <h2>Filter</h2>
        <div (click)="close()">
            <app-custom-icon
                [iconPath]="'assets/icons/general-icons.svg'"
                [imageName]="'icon-close'">
            </app-custom-icon>
        </div>
    </div>
    <mat-accordion>
        <app-sidebar-filter-panel
            *ngIf="filterForm | formControl:'title' as control"
            [title]="'Form Title'"
            [controls]="[control]"
            [isEmpty]="!control.value">
            <app-input
                [control]="control"
                [height]="40"
                [placeholder]="'Search by Form Title'"></app-input>
        </app-sidebar-filter-panel>

        <app-sidebar-filter-panel
            *ngIf="filterForm | formControl:'type' as control"
            [title]="'Form Type'"
            [controls]="[control]"
            [isEmpty]="!control.value.length">
            <div class="checkbox-list">
                <app-checkbox
                    *ngFor="let status of typeList"
                    [view]="'button'"
                    [label]="status.label"
                    [checked]="control.value | formControlContainsOption:status.value"
                    (checkedChanged)="setCheckboxValue(control, $event, status.value)"></app-checkbox>
            </div>
        </app-sidebar-filter-panel>

        <app-sidebar-filter-panel
            *ngIf="filterForm | formControl:'category' as control"
            [title]="'Form Category'"
            [controls]="[control]"
            [isEmpty]="!control.value.length">
            <div class="checkbox-list">
                <app-checkbox
                    *ngFor="let status of categoryList"
                    [view]="'button'"
                    [label]="status.label"
                    [checked]="control.value | formControlContainsOption:status.value"
                    (checkedChanged)="setCheckboxValue(control, $event, status.value)"></app-checkbox>
            </div>
        </app-sidebar-filter-panel>

        <app-sidebar-filter-panel
            *ngIf="filterForm | formControl:'owner' as control"
            [title]="'Owner'"
            [controls]="[control]"
            [isEmpty]="!control.value">
            <app-radio-list
                [options]="ownerList"
                [view]="'button-view'"
                [direction]="'list-column'"
                [currentSelection]="control.value"
                (onChange)="control.setValue($event)"></app-radio-list>
        </app-sidebar-filter-panel>
    </mat-accordion>
</div>
