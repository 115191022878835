import { Component, Input } from "@angular/core";
import { AssessmentDto, FormType } from "@/_models";
import { TableColumn } from "@/components/shared/table-header/table-header.component";
import { FormPreviewModalComponent } from "@/components/assessment/form-preview-modal/form-preview-modal.component";
import { catchError, of, tap } from "rxjs";
import { filter, switchMap } from "rxjs/operators";
import {
    CreateAssessmentDto,
    CreateMode,
} from "@/_models/assessment/CreateAssessmentDto";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AccountService } from "@/_services/account.service";
import { AssessmentService } from "@/_services";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
@Component({
    selector: "app-template-card",
    templateUrl: "./template-card.component.html",
    styleUrl: "./template-card.component.scss",
})
export class TemplateCardComponent {
    @Input() template: AssessmentDto;
    @Input() columns: TableColumn[] = [];
    @Input() formTypeList: FormType[];

    readonly creationModes = CreateMode;

    constructor(
        private modalService: NgbModal,
        private accountService: AccountService,
        private assessmentService: AssessmentService,
        private router: Router,
        private toastrService: ToastrService,
    ) {}

    openPreview() {
        const modalRef = this.modalService.open(FormPreviewModalComponent);
        modalRef.componentInstance.assessment = this.template;
    }

    clone() {
        this.accountService
            .checkFormFeature(this.template.formTypeSettings.requiresFeature)
            .pipe(
                filter((result) => result),
                switchMap(() =>
                    this.assessmentService.create({
                        method: this.template.account
                            ? this.creationModes.Copy
                            : this.creationModes.Default,
                        copyFrom: this.template.account
                            ? this.template._id
                            : this.template.title,
                    } as CreateAssessmentDto),
                ),
                tap((assessment: AssessmentDto) =>
                    this.router.navigate([
                        "./assessments",
                        assessment._id,
                        "edit",
                    ]),
                ),
                catchError((error) => {
                    this.toastrService.error(error, "Error creating form");
                    return of(error);
                }),
            )
            .subscribe();
    }
}
