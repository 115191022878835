import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AssessmentSettingsModalComponent } from "@/components/assessment/assessment-settings-modal/assessment-settings-modal.component";

@Component({
    selector: "app-create-assessment",
    styleUrls: ["./create-assessment.component.scss"],
    templateUrl: "./create-assessment.component.html",
})
export class CreateAssessmentComponent implements OnInit {
    constructor(private modalService: NgbModal) {}

    ngOnInit() {}

    createNewAssessmentForm() {
        const loading = this.modalService.open(
            AssessmentSettingsModalComponent,
            { backdrop: true, keyboard: true },
        );
        const component =
            loading.componentInstance as AssessmentSettingsModalComponent;
        component.assessment = null;
    }
}
