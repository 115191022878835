import { Component, Inject, OnInit } from "@angular/core";
import { filter, startWith, switchMap } from "rxjs/operators";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { USER_IS_AT_LEAST_ADMIN } from "@/_services";
import { map, Observable, shareReplay, tap } from "rxjs";
import { AssessmentDto } from "@/_models";
import { MenuService } from "@/_services/menu.service";
import { TabItem } from "@/_models/menu-item";

@Component({
    selector: "app-assessment",
    templateUrl: "./assessment.component.html",
    styleUrls: ["./assessment.component.scss"],
})
export class AssessmentComponent implements OnInit {
    assessment$: Observable<AssessmentDto>;
    isEdited$: Observable<boolean>;

    navTabs$: Observable<TabItem[]>;

    currentTab$: Observable<TabItem>;
    isMenuOpened: boolean;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private menuService: MenuService,
        @Inject(USER_IS_AT_LEAST_ADMIN)
        public isAtLeastAdmin$: Observable<boolean>,
    ) {}

    ngOnInit() {
        this.isEdited$ = this.router.events.pipe(
            filter((e) => e instanceof NavigationEnd),
            startWith(this.router),
            map(() => this.router.url.includes("/edit")),
        );

        this.activatedRoute.params
            .pipe(
                tap(() => {
                    this.assessment$ = this.activatedRoute.data.pipe(
                        switchMap(
                            (data) => data.form as Observable<AssessmentDto>,
                        ),
                    );
                }),
                tap((params) => {
                    this.navTabs$ = this.menuService
                        .createAssessmentEditorMenu(params["assessmentId"])
                        .pipe(shareReplay());
                }),
                tap(() => {
                    this.currentTab$ = this.menuService.getCurrentTab(
                        this.navTabs$,
                    );
                }),
            )
            .subscribe();
    }

    toggleMenu(state: boolean) {
        this.isMenuOpened = state;
    }
}
