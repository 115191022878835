<div class="table-filters">
    <div class="filter-button" (click)="openFilterMenu()">
        <app-custom-icon
            [iconPath]="'assets/icons/general-icons.svg'"
            [imageName]="'filter'"
            [imageSize]="'18'">
        </app-custom-icon>
        <span>Filter</span>
    </div>
    <ng-container *ngFor="let filter of currentFilters | keyvalue">
        <ng-container *ngIf="isDisplayable(filter)" [ngSwitch]="filter.key">
            <app-topbar-filter-button
                *ngSwitchCase="'title'"
                [filter]="filter"
                [dataToDisplay]="'Form Title: ' + filter.value"
                (resetClicked)="resetFilter(filter.key)"></app-topbar-filter-button>
            <app-topbar-filter-button
                *ngSwitchCase="'type'"
                [filter]="filter"
                [dataToDisplay]="filter.value | getFilterList:typeList:'type':'types'"
                (resetClicked)="resetFilter(filter.key)"></app-topbar-filter-button>
            <app-topbar-filter-button
                *ngSwitchCase="'category'"
                [filter]="filter"
                [dataToDisplay]="filter.value | getFilterList:categoryList:'category':'categories'"
                (resetClicked)="resetFilter(filter.key)"></app-topbar-filter-button>
            <ng-container *ngIf="isAdmin$ | async">
                <app-topbar-filter-button
                    *ngSwitchCase="'owner'"
                    [filter]="filter"
                    [dataToDisplay]="'Owner: ' + (filter.value | findInList:ownerList)"
                    (resetClicked)="resetFilter(filter.key)"></app-topbar-filter-button>
            </ng-container>
        </ng-container>
    </ng-container>
</div>
