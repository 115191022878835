import { Component, Inject, OnInit } from "@angular/core";
import { expandingFabAnimations } from "@/_utility/animations";
import { MenuService } from "@/_services/menu.service";
import { Observable } from "rxjs";
import { MenuSection } from "@/_models/menu-item";
import { USER_IS_AT_LEAST_ADMIN } from "@/_services";

@Component({
    selector: "app-form-list",
    templateUrl: "./form-list.component.html",
    styleUrls: ["./form-list.component.scss"],
    animations: [expandingFabAnimations],
})
export class FormListComponent implements OnInit {
    formMenu$: Observable<MenuSection>;

    constructor(
        private menuService: MenuService,
        @Inject(USER_IS_AT_LEAST_ADMIN)
        public isAtLeastAdmin$: Observable<boolean>,
    ) {}

    ngOnInit() {
        this.formMenu$ = this.menuService.createFormMenu();
    }
}
