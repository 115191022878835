<mat-drawer-container [hasBackdrop]="true">
    <mat-drawer #sidenav [mode]="'over'">
        <app-template-list-filter-sidebar
            *ngIf="formTypeList$ | async"
            [filterForm]="filterForm"
            (closeButtonClicked)="sidenav.close()"
            [typeList]="formattedTypeList"
            [categoryList]="formattedCategoryList"
            [ownerList]="ownerList$ | async"></app-template-list-filter-sidebar>
    </mat-drawer>
    <mat-drawer-content class="app-content listings">
        <app-template-list-filter-topbar
            *ngIf="formTypeList$ | async"
            [filterForm]="filterForm"
            [currentFilters]="currentFilters"
            (filterButtonClicked)="sidenav.open()"
            [typeList]="formattedTypeList"
            [categoryList]="formattedCategoryList"
            [ownerList]="ownerList$ | async"></app-template-list-filter-topbar>

        <app-loading-indicator
            *ngIf="!(formList$ | async); else content"></app-loading-indicator>

        <ng-template #content>
            <app-table-header
                *appDesktop
                [columns]="columns"
                [gridTemplate]="'repeat(' + columns.length + ', 1fr) 2fr'"
                [isSortable]="true"
                [currentSorting]="currentSorting"
                (sortSelected)="setSorting($event)"></app-table-header>

            <ng-container *ngIf="(formList$|async) as formList">
                <div class="template-list-wrapper">
                    <app-template-card
                        *ngFor="let formTemplate of formList; trackBy: trackFlightId"
                        [template]="formTemplate"
                        [columns]="columns"
                        [formTypeList]="formTypeList$ | async"
                        [formCategoryList]="formattedCategoryList"></app-template-card>
                </div>
            </ng-container>
        </ng-template>

    </mat-drawer-content>
</mat-drawer-container>
