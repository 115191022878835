<app-page-topbar [title]="'Forms'"></app-page-topbar>

<div>
    <div class="app-content listings">
        <ul *ngIf="formMenu$ | async as formMenu" class="form-list">
            <li *ngFor="let item of formMenu.items">
                <a
                    class="menu-item"
                    [routerLink]="item.routerLink"
                    [routerLinkActive]="'active'"
                    appFeatureHighlight
                    [feature]="item.highlight">
                    <div>
                        <app-list-icon></app-list-icon>
                        <div class="menu-text">
                            <div class="menu-label">{{ item.label }}</div>
                            <app-feature-highlight-badge></app-feature-highlight-badge>
                        </div>
                    </div>
                    <div class="menu-icon">
                        <app-custom-icon
                            [iconPath]="'assets/icons/general-icons.svg'"
                            [imageName]="'chevron-right'">
                        </app-custom-icon>
                    </div>
                </a>
            </li>
        </ul>

        <ng-container *ngIf="(isAtLeastAdmin$ | async)">
            <div class="right-corner-control blue" *appMobile>
                <a [routerLink]="'/assessments/new'">
                    <span>Form Templates</span>
                </a>
            </div>
        </ng-container>
    </div>
</div>
