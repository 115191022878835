import { Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { UserService } from "@/_services";
import { map } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class GeneralUserGuard {
    constructor(
        private userService: UserService,
        private router: Router,
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this.userService.isAdmin().pipe(
            map((hasRole: boolean) => {
                if (!hasRole) {
                    this.router.navigateByUrl("/");
                }
                return hasRole;
            }),
        );
    }
}
