<div class="item-wrapper">
    <ng-container *ngFor="let column of columns">
        <ng-container *ngIf="column.isDisplayed" [ngSwitch]="column.label">
            <div class="field" *ngSwitchCase="'Type'">
                <span>{{ formTypeList | getFormType:template.formTypeSettings[column.value] }}</span>
            </div>

            <div class="field" *ngSwitchCase="'Category'">
                <span>{{ template.formTypeSettings[column.value] | getFormCategory }}</span>
            </div>

            <div class="field" *ngSwitchCase="'Owner'">
                <span>{{ template?.account?.name || 'Preflight Mitigator' }}</span>
            </div>

            <div class="field" *ngSwitchDefault>
                <span>{{ template[column.value] }}</span>
            </div>
        </ng-container>
    </ng-container>

    <div class="button-container">
        <button class="btn-secondary" (click)="openPreview()">Preview</button>
        <button class="btn-primary" (click)="clone()">Clone & Customize</button>
    </div>
</div>


<div class="item-wrapper-mobile">
    <app-flight-card-mobile [content]="'grid'">
        <ng-container title>
            {{template.title}}
        </ng-container>

        <ng-container content>
            @for (column of columns; track column) {
                @if (column.isDisplayed) {
                    @switch (column.label) {
                        @case ('Title') {}
                        @case ('Type') {
                            <div class="content-field flex flex-column">
                                <span class="font-bold">{{column.label}}</span>
                                <span>{{ formTypeList | getFormType:template.formTypeSettings[column.value] }}</span>
                            </div>
                        }
                        @case ('Category') {
                            <div class="content-field flex flex-column">
                                <span class="font-bold">{{column.label}}</span>
                                <span>{{ template.formTypeSettings[column.value] | getFormCategory }}</span>
                            </div>
                        }
                        @case ('Owner') {
                            <div class="content-field flex flex-column">
                                <span class="font-bold">{{column.label}}</span>
                                <span>{{ template?.account?.name || 'Preflight Mitigator' }}</span>
                            </div>
                        }
                        @default {
                            <div class="content-field flex flex-column">
                                <span class="font-bold">{{column.label}}</span>
                                <span>{{column.value}}</span>
                            </div>
                        }
                    }
                }
            }
        </ng-container>

        <ng-container actions>
            <button class="btn-secondary" (click)="openPreview()">Preview</button>
            <button class="btn-primary" (click)="clone()">Clone & Customize</button>
        </ng-container>
    </app-flight-card-mobile>
</div>
